import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import { device } from '../providers/theme';
import SEO from '../components/seo';
import BlogPreview from '../components/BlogPost';

const Block = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 5vw;
  @media ${device.mobile} {
    justify-content: center;
    max-width: 100%;
  }
  a {
    font-weight: 400;
    text-decoration: underline;
  }
`;

const Intro = styled.div`
  padding: 22px 0;
  h2,
  p {
    margin: 0;
  }

  div {
    margin-top: 12px;
  }
`;

function BlogPage({
  data: {
    allMarkdownRemark: { edges }
  }
}) {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <BlogPreview key={edge.node.id} post={edge.node} />);

  return (
    <React.Fragment>
      <SEO
        title={'Blog'}
        description={
          'Find viden og oplevelser fra andre på vores blog. Har du selv en oplevelse med vin du har lyst til at dele med andre, tager vi meget gerne imod en artikel fra dig'
        }
      />
      <Layout title="Blog" withoutSeo>
        <Block>
          <Intro>
            <h2>Har du en vinoplevelse, du gerne vil dele med andre?</h2>
            <div>
              <p>
                Det kan være alt lige fra en anbefaling af en vin, til et land
                man skal opleve for vinens skyld?
              </p>
              <p>
                Du er meget velkommen til at sende en artikel til{' '}
                <a href="mailto:jonaskkristensen@hotmail.dk">vinkammeret</a>
              </p>
            </div>
          </Intro>
          {Posts}
        </Block>
      </Layout>
    </React.Fragment>
  );
}

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            type
            preview
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
