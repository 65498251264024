import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { device } from '../../providers/theme';

const Container = styled.article`
  position: relative;
  height: 470px;
  max-width: 520px;
  flex-basis: 45%;
  margin: 42px 0;

  &:nth-child(even) {
    .flipped-svg {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    margin: 22px 0;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0 1px 1px;
`;

const Image = styled(Img)`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 2px;
`;

const SVG = styled.svg`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -2px;
  width: 100%;

  @media ${device.tablet} {
    margin-top: -1px;
  }
  path {
    fill: #fff;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  padding: 22px;
`;

const BlogTitle = styled.h3`
  font-size: 1.2rem;
  margin: 0;
  width: 100%;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BlogSnippet = styled.div`
  position: relative;
  max-height: 68px;
  padding-top: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media ${device.mobile} {
    max-height: 60px;
  }

  .fade-out {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    opacity: 0.7;
    background: #fff;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  text-align: center;
  width: 104px;
  margin-top: 18px;
  display: block;
  padding: 8px 12px;
  color: ${props => props.articleType};
  text-decoration: none !important;
  border-radius: 8px;
  border: 2px solid ${props => props.articleType};
  transition: background 0.1s ease-in;
  font-weight: 600;

  @media ${device.mobile} {
    padding: 6px;
    right: 0;
  }
`;

function BlogPreview({ post }) {
  const handleTheme = theme => {
    switch (theme) {
      case 'redWine':
        return '#722f37';
      case 'whiteWine':
        return '#d4c52d';
      case 'rose':
        return '#ee7272';
      case 'bubbles':
        return '#d4c52d';
      case 'dessertWine':
        return '#400000';
      default:
        return '#722f37';
    }
  };

  return (
    <Container>
      <ImageContainer>
        {post?.frontmatter?.image?.childImageSharp?.fluid ? (
          <Image
            fluid={post?.frontmatter?.image?.childImageSharp?.fluid}
            alt={post?.frontmatter?.title}
          />
        ) : (
          <img src={post.frontmatter.image} />
        )}
        <SVG
          className="flipped-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path d="M0,128L60,160C120,192,240,256,360,277.3C480,299,600,277,720,250.7C840,224,960,192,1080,202.7C1200,213,1320,267,1380,293.3L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </SVG>
      </ImageContainer>
      <TextContainer>
        <BlogTitle>{post.frontmatter.title}</BlogTitle>
        <BlogSnippet
          className="blog-post-content"
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.preview
              ? `<p>${post.frontmatter.preview}</p>`
              : post.html
          }}
        />

        <StyledLink
          to={post.frontmatter.path}
          articleType={handleTheme(post.frontmatter.type)}
        >
          Læs mere
        </StyledLink>
      </TextContainer>
    </Container>
  );
}

export default BlogPreview;
